import { createContext, useContext, useState } from "react";

export const contextPayment = createContext({});

export function usePaymentContext() {
  return useContext(contextPayment);
}

export function PaymentProvider({ children }) {
  const [preferenceId, setPreferenceId] = useState("");
  const [dataClient, setDataClient] = useState({});
  const [isNotified, setIsNotified] = useState(false);
  const [messageNotifed, setMessageNotifed] = useState({});

  function handlePreferenceId(id) {
    setPreferenceId(id);
  }
  function handleSetDataClient(data) {
    setDataClient(data);
  }

  function handleIsNotified(message) {
    setMessageNotifed(message);
    setIsNotified(true);
  }

  function handleCloseIsNotified() {
    setIsNotified(false);
  }

  return (
    <contextPayment.Provider
      value={{
        preferenceId,
        handlePreferenceId,
        dataClient,
        handleSetDataClient,
        isNotified,
        handleIsNotified,
        handleCloseIsNotified,
        messageNotifed,
      }}
    >
      {children}
    </contextPayment.Provider>
  );
}
