import * as Yup from "yup";

export const FormSchema = Yup.object().shape({
  name_client: Yup.string()
    .max(150, "este campo tem o tamanho maximo de 150 caracteres")
    .required("Informe seu nome"),
  email: Yup.string()
    .max(150, "este campo tem o tamanho maximo de 150 caracteres")
    .email("Informe um email válido")
    .required("Informe seu email"),
  phone: Yup.string().max(13, "Informe um numero de telefone válido"),
  id_church: Yup.number().required("Informe a qual congreção pertence"),
  formOfpayment: Yup.string().required("Informe a forma de pagamento"),
});
