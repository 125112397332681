import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import { RouterWrapper } from "./routes/RoutesWrapper";
import { QueryClient, QueryClientProvider } from "react-query";
import { CssBaseline, createTheme } from "@mui/material";
import { PaymentProvider } from "./context/paymentContext";

import { ThemeProvider } from "@mui/material";

import { themeOptions } from "./theme/themeDefault";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PaymentProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <RouterWrapper />
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </PaymentProvider>
  </React.StrictMode>
);
