import { Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/home/home";
import { TemplateDefault } from "../template/TemplateDefault";
import { Payments } from "../pages/payments/Payments";
import { SuccessPayment } from "../pages/sucessPayment/SuccessPayment";
import { ErrorPayment } from "../pages/ErrorPayment /ErrorPayment";
import { SuccessPreRegister } from "../pages/sucessPreRegister/SuccessPreRegister";
import { WaitingPayment } from "../pages/waiting/WaitingPayment";

export function RouterWrapper() {
  return (
    <Routes>
      <Route path="/" element={<TemplateDefault />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/checkout" element={<Payments />} />
        <Route path="/success" element={<SuccessPayment />} />
        <Route path="/error" element={<ErrorPayment />} />
        <Route path="/success_pre_register" element={<SuccessPreRegister />} />
        <Route path="/waiting" element={<WaitingPayment />} />
      </Route>
    </Routes>
  );
}
