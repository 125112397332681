import { Box, Stack, Typography } from "@mui/material";
import { FormRegisters } from "./components/formRegister/FormRegister";

export function HomePage() {
  return (
    <Box width={["100%", "100%", "70%", "70%"]} mt={1}>
      <Stack direction={["column", "column", "row"]} width="100%" gap={2}>
        <Stack flex="1" p={2} gap={2}>
          <Typography>Caro participante,</Typography>
          <Typography>
            Para nós, é uma grande honra tê-lo conosco na 12ª edição da nossa
            <strong>EBOM (Escola Bíblica de Obreiros e Membros)</strong>. Em
            2011, tivemos a primeira edição, então chamada apenas de{" "}
            <strong>EBO (Escola Bíblica de Obreiros)</strong>, sendo um trabalho
            essencialmente voltado para obreiros, sob a gestão do então pastor
            setorial <strong>Pedro Barbosa da Costa Filho (In memoriam)</strong>
            .
          </Typography>
          <Typography>
            O sucesso da primeira edição foi tão grande que não foi mais
            possível restringi-la apenas aos obreiros, tornando-se um evento de
            grandes proporções para todos os obreiros e membros do setor. Este
            ano de 2024 marca a{" "}
            <strong>
              2ª edição da nossa Escola Bíblica de Obreiros e Membros
            </strong>
            . E nesta edição estamos cheios de novidades Nos sentimos felizes
            por a cada ano poder nos superar, e oferecer a você caro
            participante uma Escola Bíblica com excelente qualidade de
            ensinadores e estrutura, que por sinal, está cada vez mais
            tecnológica a forma de inscrição e pagamento, tudo isso para Gloria
            de Deus.
          </Typography>
          <Typography>
            É por tudo isto que queremos convidar você para mais este ano deixar
            seu nome marcado nesta história.
          </Typography>
          <Typography>
            Bem-vindo à 12ª EBOM (Escola Bíblica de Obreiros e Membros)!
          </Typography>
        </Stack>
        <FormRegisters />
      </Stack>
    </Box>
  );
}
