// import alphacampus from "../assets/img/supporters/alphacampus.png";
// import vision from "../assets/img/supporters/vision.png";

export const supporters = [
  //   {
  //     src: vision,
  //     alt: "vision",
  //     url: "https://www.instagram.com/otica_super_vision/",
  //     tooltip: "Ótica Super Vision",
  //   },
  //   {
  //     src: alphacampus,
  //     alt: "alphacampus",
  //     url: "https://www.alphacampus.com.br/",
  //     tooltip: "Alphacampus cemitério e crematório",
  //   },
];
