import { Alert, Snackbar } from "@mui/material";

export function AlertsComponents({ message, type, isOpen, setClose }) {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={setClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
