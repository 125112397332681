import {
  ArrowBack,
  CheckCircleOutline,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import logoAdi from "../../assets/img/logoAdi.png";
import { useNavigate } from "react-router-dom";

export function WaitingPayment() {
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box width={["100%", "100%", "60%", "50%"]} sx={{ overflowX: "hidden" }}>
      <Stack gap={2} alignItems="center" width="100%">
        <Stack flex={1} alignItems="flex-end">
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => navigate("/")}
          >
            Voltar ao inicio
          </Button>
        </Stack>

        <InfoOutlined sx={{ fontSize: isMobile ? 80 : 120 }} color="info" />
        <Stack alignItems="center">
          <Typography variant={isMobile ? "h4" : "h2"}></Typography>
          <Typography variant="body1">Parabens!</Typography>
        </Stack>

        <Typography align="center">
          Sua inscrição foi realizada com sucesso. No entanto, estamos
          aguardando a confirmação do pagamento. Assim que o pagamento for
          confirmado, você receberá um e-mail com um QR code do evento.
          Agradecemos pela sua participação e aguardamos ansiosamente para vê-lo
          na 12ª EBOM.
        </Typography>

        <Typography>
          <strong>Deus lhe abençoe ! </strong>
        </Typography>
        <img src={logoAdi} alt="Logo ADI" width={200} />
      </Stack>
    </Box>
  );
}
