import { useQuery } from "react-query";
import { api } from "../api";

export function useApiChurchs() {
  const { data: dataChurch, isLoading: isLoadingDataChurch } = useQuery(
    "churchs",
    () => {
      return api.get("/api/church").then((response) => response.data.data);
    }
  );

  return { dataChurch, isLoadingDataChurch };
}
