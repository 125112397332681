import { useState } from "react";
import { useApiChurchs } from "../../../../../services/hooks/useApiChurchs";
import { useApiRegisters } from "../../../../../services/hooks/useApiRegister";
import { useFormik } from "formik";
import { FormSchema } from "../../../../../helpers/valid";
import { usePaymentContext } from "../../../../../context/paymentContext";
import { useApiConfigures } from "../../../../../services/hooks/useApiConfigures";
import { formatCurrency } from "../../../../../helpers/formated";

export function useFormRegister() {
  const { dataChurch, isLoadingDataChurch } = useApiChurchs();
  const { sendPreRegister, isLoadingPreRegister } = useApiRegisters();
  const [isShepherd, setIsShepherd] = useState(false);

  const { dataConfigure } = useApiConfigures();

  const { handleSetDataClient } = usePaymentContext();

  const formik = useFormik({
    initialValues: {
      name_client: "",
      email: "",
      phone: "",
      id_church: "",
      formOfpayment: "",
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      values.shepherd = isShepherd ? 1 : 0;
      sendPreRegister({ data: values });

      const churchFilter = dataChurch?.find(
        (church) => church.id === values.id_church
      );

      const dataClient = {
        ...values,
        name_church: churchFilter.name_church,
        value: formatCurrency(dataConfigure?.value_sale),
      };

      handleSetDataClient(dataClient);
    },
  });

  return {
    dataChurch,
    isLoadingDataChurch,
    formik,
    isShepherd,
    setIsShepherd,
    isLoadingPreRegister,
  };
}
