import {
  Box,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Outlet } from "react-router-dom";

import logo from "../assets/img/logo.png";
import { supporters } from "../helpers/supporters";
import { AlertsComponents } from "../components/alertsComponents/AlertsComponents";
import { usePaymentContext } from "../context/paymentContext";

import background from "../assets/img/background.jpeg";

export function TemplateDefault() {
  const theme = useTheme();
  const { isNotified, handleCloseIsNotified, messageNotifed } =
    usePaymentContext();

  const heightFooter = theme.spacing(14);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundColor: theme.palette.grey[100],
        minHeight: "100vh",
        minWidth: "100vw",
        m: 0,

        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundAttachment: "fixed",
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          width: "100%",
          zIndex: 2,
        }}
      >
        <img src={logo} alt="Logo" height={220} style={{ objectFit: "fill" }} />
      </Stack>

      <Stack
        flex={1}
        alignItems="center"
        mb={heightFooter}
        sx={{ overflowX: "hidden" }}
      >
        <Outlet />
      </Stack>
      <Stack
        position="fixed"
        bottom={0}
        height={heightFooter}
        p={2}
        zIndex={2}
        sx={{
          background: "#006564",
          color: theme.palette.common.white,
          width: "100%",
          boxShadow: theme.shadows[2],
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" gap={3}>
          <Typography variant="caption">Nossos apoiadores:</Typography>

          <Stack direction="row" gap={3}>
            {supporters.map((supporter) => (
              <Tooltip key={supporter.alt} title={supporter.tooltip}>
                <img
                  src={supporter.src}
                  alt={supporter.alt}
                  onClick={() => window.open(supporter.url, "_blank")}
                  style={{ cursor: "pointer", maxHeight: isMobile ? 30 : 50 }}
                />
              </Tooltip>
            ))}
          </Stack>
        </Stack>
        <Typography variant="caption" align="center">
          © 2022 - Todos os direitos reservados
        </Typography>
      </Stack>

      <AlertsComponents
        isOpen={isNotified}
        message={messageNotifed?.label}
        type={messageNotifed?.type || "info"}
        setClose={handleCloseIsNotified}
      />
    </Box>
  );
}
