import axios from "axios";

const ENDPOINT =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SERVICE_URL
    : process.env.REACT_APP_SERVICE_URL_DEV;

export const api = axios.create({
  baseURL: ENDPOINT,
});
