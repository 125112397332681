import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormRegister } from "./hooks/useFormRegister";
import { LoadingPage } from "../../../../components/loadingPage/LoadingPage";
import ReactInputMask from "react-input-mask";
import { Send } from "@mui/icons-material";

export function FormRegisters() {
  const {
    formik,
    dataChurch,
    isLoadingPreRegister,
    isShepherd,
    setIsShepherd,
    isLoadingDataChurch,
  } = useFormRegister();

  if (isLoadingDataChurch) {
    return <LoadingPage />;
  }

  return (
    <Stack flex={1} py={2} px={3}>
      <Stack pb={3}>
        <Typography variant="body1">Faça já sua inscrição</Typography>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="*Nome"
            name="name_client"
            variant="outlined"
            value={formik.values.name_client}
            onChange={formik.handleChange}
            error={formik.errors.name_client && formik.touched.name_client}
            helperText={
              formik.errors.name_client && formik.touched.name_client
                ? formik.errors.name_client
                : ""
            }
            disabled={isLoadingPreRegister}
          />
          <TextField
            label="*Email"
            name="email"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email && formik.touched.email}
            helperText={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : ""
            }
            disabled={isLoadingPreRegister}
          />
          <ReactInputMask
            label="Celular"
            name="phone"
            variant="outlined"
            mask="99999-9999"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.errors.phone && formik.touched.phone}
            helperText={
              formik.errors.phone && formik.touched.phone
                ? formik.errors.phone
                : ""
            }
            disabled={isLoadingPreRegister}
          >
            {(inputProps) => <TextField {...inputProps} />}
          </ReactInputMask>

          <FormControl fullWidth>
            <InputLabel id="select-label">* Congregação</InputLabel>
            <Select
              labelId="select-label"
              label="* Congregação"
              name="id_church"
              value={formik.values.id_church}
              onChange={formik.handleChange}
              error={formik.touched.id_church && formik.errors.id_church}
              helperText={
                formik.touched.id_church && formik.errors.id_church
                  ? formik.errors.id_church
                  : ""
              }
              disabled={isLoadingPreRegister}
            >
              <MenuItem value=""></MenuItem>
              {dataChurch?.length &&
                dataChurch?.map((church) => (
                  <MenuItem key={`church-${church.id}`} value={church.id}>
                    {church.name_church}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="select-label">* Forma de pagamento</InputLabel>
            <Select
              labelId="select-label"
              label="* Forma de pagamento"
              name="formOfpayment"
              value={formik.values.formOfpayment}
              onChange={formik.handleChange}
              error={
                formik.touched.formOfpayment && formik.errors.formOfpayment
              }
              helperText={
                formik.touched.formOfpayment && formik.errors.formOfpayment
                  ? formik.errors.formOfpayment
                  : ""
              }
              disabled={isLoadingPreRegister}
            >
              <MenuItem value="digital">
                {" "}
                Pix, cartão de crédito ou débito
              </MenuItem>
              <MenuItem value="presential"> Presencial</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                name="shepherd"
                checked={isShepherd}
                onChange={() => setIsShepherd(!isShepherd)}
                disabled={isLoadingPreRegister}
              />
            }
            label="Sou pastor."
            sx={{ display: "flex", marginTop: "20px", color: "#222" }}
          />

          <Typography style={{ color: "red" }}>* campo obrigatório</Typography>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            width={"100%"}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              color="warning"
              loading={isLoadingPreRegister}
              startIcon={<Send />}
              fullWidth
              size="large"
              sx={{
                backgroundColor: "#006564",
                "&:hover": { background: "#004544" },
              }}
            >
              Enviar
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}
