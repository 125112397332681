import { CircularProgress, Stack } from "@mui/material";

export function LoadingPage() {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        p: 20,
        flex: 1,
      }}
    >
      <CircularProgress />
    </Stack>
  );
}
