import { ArrowBack, CheckCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import logoAdi from "../../assets/img/logoAdi.png";
import { useNavigate } from "react-router-dom";

export function SuccessPayment() {
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box width={["100%", "100%", "60%", "50%"]} sx={{ overflowX: "hidden" }}>
      <Stack gap={2} alignItems="center" width="100%">
        <Stack flex={1} alignItems="flex-end">
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => navigate("/")}
          >
            Voltar ao inicio
          </Button>
        </Stack>
        <CheckCircleOutline
          sx={{ fontSize: isMobile ? 80 : 120 }}
          color="success"
        />
        <Stack alignItems="center">
          <Typography variant={isMobile ? "h4" : "h2"}>Parabéns</Typography>
          <Typography variant="body1">
            Sua inscrição foi realizada com sucesso
          </Typography>
        </Stack>

        <Typography align="center">
          Você receberá um e-mail com uma notificação contendo o seu QR code.
          Esse QR code será utilizado para acessar o evento da EBOM 12. No dia
          do evento, por favor, apresente-se à equipe de apoio para retirar o
          seu material e o crachá físico.
        </Typography>
        <Typography align="center">
          Caso não receba o e-mail, por favor, entre em contato com a secretaria
          da igreja.
        </Typography>
        <Typography>
          <strong>Deus lhe abençoe e nos vemos na EBOM 12! </strong>
        </Typography>
        <img src={logoAdi} alt="Logo ADI" width={200} />
      </Stack>
    </Box>
  );
}
