import { useMutation } from "react-query";
import { api } from "../api";
import { usePaymentContext } from "../../context/paymentContext";
import { useNavigate } from "react-router-dom";

export function useApiRegisters() {
  const { handlePreferenceId, handleIsNotified } = usePaymentContext();
  const navigate = useNavigate();

  const { mutate: sendPreRegister, isLoading: isLoadingPreRegister } =
    useMutation(
      "registers",
      async ({ data }) => {
        return api
          .post("/api/clients/preregister", data)
          .then((response) => response.data);
      },
      {
        onSuccess: (data) => {
          handlePreferenceId(data.preference_id);
          data.FormOfPayment === "digital"
            ? navigate("/checkout")
            : navigate("/success_pre_register");
        },
        onError: (error) => {
          const { message } = error?.response?.data || {};

          handleIsNotified({
            label: message || "Erro ao realizar a pré-inscrição",
            type: "error",
          });
        },
      }
    );

  return { sendPreRegister, isLoadingPreRegister };
}
