import { Box, Divider, Paper, Stack, Typography } from "@mui/material";

import checkoutImg from "../../assets/img/checkout.png";

import logo from "../../assets/img/logo.png";
import { Wallet, initMercadoPago } from "@mercadopago/sdk-react";
import { usePaymentContext } from "../../context/paymentContext";

const keyPublicMP =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY_PRD
    : process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY;

initMercadoPago(keyPublicMP);

export function Payments() {
  const { dataClient, preferenceId } = usePaymentContext();

  return (
    <Box width={["100%", "100%", "70%", "70%"]} mt={1}>
      <Stack
        direction={["column", "column", "column", "row"]}
        width="100%"
        gap={2}
      >
        <Stack flex="1" p={2} gap={2}>
          <Stack gap={1}>
            <Typography variant="h3">Estamos quase la !</Typography>
            <Typography variant="subtitle2">
              Por favor, verifique os seus dados listados ao lado. Confira o
              valor da sua inscrição e, em seguida, clique no botão "Pagar com
              mercado pago".
            </Typography>
            <img src={checkoutImg} alt="checkout" width={"100%"} />
          </Stack>
        </Stack>
        <Stack flex={1} p={2} gap={2}>
          <Paper>
            <Stack gap={2} p={2}>
              <Typography variant="h5">Resumo da compra</Typography>
              <Stack gap={1}>
                <Typography variant="subtitle2">
                  <strong>Nome:</strong> {dataClient?.name_client}
                </Typography>
                <Typography variant="subtitle2">
                  <strong>Email:</strong>
                  {dataClient?.email}
                </Typography>
                <Typography variant="subtitle2">
                  <strong>Telefone:</strong> {dataClient?.phone}
                </Typography>
                <Typography variant="subtitle2">
                  <strong>Igreja:</strong> {dataClient?.name_church}
                </Typography>
              </Stack>
              <Divider orientation="horizontal" flexItem />
              <Stack direction="row" gap={5}>
                <img src={logo} alt="Logo" width={180} />
                <Stack>
                  <Typography variant="subtitle2" mb={1}>
                    <strong>Inscrição 12ª EBOM</strong>
                  </Typography>
                  <Typography variant="subtitle2">
                    <strong>Valor:</strong> {dataClient?.value}
                  </Typography>
                </Stack>
              </Stack>
              <Divider orientation="horizontal" flexItem />
              <Stack>
                <Wallet
                  initialization={{
                    preferenceId: preferenceId,
                  }}
                  locale="pt-BR"
                />
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
}
