import { ArrowBack, Block } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";

import logoAdi from "../../assets/img/logoAdi.png";
import { useNavigate } from "react-router-dom";

export function ErrorPayment() {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Stack gap={2} alignItems="center" width={["100%", "100%", "50%"]}>
        <Stack width="100%" alignItems="flex-end">
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => navigate("/")}
          >
            Voltar ao inicio
          </Button>
        </Stack>
        <Block sx={{ fontSize: 180 }} color="error" />
        <Stack alignItems="center" width="100%">
          <Typography variant="h2">Opps !!</Typography>
          <Typography variant="body1" align="center">
            Ocorrereu um erro ao processar o pagamento
          </Typography>
        </Stack>

        <Typography align="center">
          Por favor, tente novamente mais tarde ou entre em contato com a
          secretaria da igreja.
        </Typography>

        <Typography>
          <strong>Deus lhe abençoe EBOM 12! </strong>
        </Typography>
        <img src={logoAdi} alt="Logo ADI" width={200} />
      </Stack>
    </Box>
  );
}
