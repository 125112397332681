import { useQuery } from "react-query";
import { api } from "../api";

export function useApiConfigures() {
  const { data: dataConfigure, isLoading } = useQuery(
    "apiConfigures",
    async () => {
      return api
        .get("api/configures")
        .then((response) => response.data.configures);
    }
  );

  return { dataConfigure, isLoading };
}
